@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
img
------------------------------------------------------------*/
.c-imgtext1 {
    position: relative;
    padding: 152px 0 120px;

    @include r.sp {
        padding: 0;
    }

    //-----------------------------------------------------
    &__inner {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;

        @include r.sp {
            padding: 0;
        }
    }

    //-----------------------------------------------------
    &__info {
        width: 50%;
        position: relative;
        z-index: 2;

        @include r.sp {
            width: 100%;
            margin: 24px 0 0;
        }
    }

    //-----------------------------------------------------
    &__ttl {
        width: 50%;
        font-size: 3.6rem;
        line-height: 1.75;
        color: v.$text-color;
        font-weight: bold;
        font-feature-settings: "palt";
        position: relative;
        z-index: 2;

        @include r.sp {
            width: 100%;
            text-align: center;
            font-size: 2rem;
            line-height: 1.5;
        }
    }

    //-----------------------------------------------------
    &__txt {
        font-size: 1.6rem;
        line-height: 1.62;
        color: v.$text-color;
        font-weight: 500;
        font-feature-settings: "palt";
        margin: 41px 0 0;

        @include r.sp {
            text-align: right;
            font-size: 1rem;
            line-height: 2;
            margin: 0;
        }
    }

    //-----------------------------------------------------
    &__txt2 {
        font-size: 3rem;
        line-height: 1.5;
        color: v.$text-color;
        font-weight: bold;
        margin: 17px 0 0;

        @include r.sp {
            text-align: right;
            font-size: 1.8rem;
            margin: 10px 0 0;
        }
    }

    //-----------------------------------------------------
    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        @include r.sp {
            position: relative;
            height: auto;
            margin: 24px 0 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top right;
        }
    }
}

/*
------------------------------------------------------------*/
.c-imgtext2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    $root: &;

    //-----------------------------------------------------
    &__info {
        width: 50%;

        @include r.sp {
            width: 100%;
        }
    }

    //-----------------------------------------------------
    &__txt {
        font-size: 1.6rem;
        line-height: 1.88;
        color: v.$color8;
        font-weight: 500;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 1.84;
        }

        + #{$root}__txt {
            margin: 29px 0 0;

            @include r.sp {
                margin: 22px 0 0;
            }
        }
    }

    //-----------------------------------------------------
    &__img {
        width: 46%;
        padding: 4px 0 0;

        @include r.sp {
            width: 100%;
            padding: 0;
            margin: 12px 0 0;
        }

        img {
            width: 100%;
        }
    }

    .c-btn1 {
        margin: 31px auto 0;
        max-width: 380px;
        padding: 12px 30px 10px 40px;

        @include r.sp {
            margin: 42px auto 0;
            font-size: 1.2rem;
            padding: 15px 20px 11px;

            &::after {
                right: 9px;
            }
        }
    }

    //-----------------------------------------------------
    &--reverse {
        flex-direction: row-reverse;

        #{$root}__info {
            width: 51%;

            @include r.sp {
                width: 100%;
            }
        }

        #{$root}__txt {
            line-height: 1.65;

            @include r.sp {
                line-height: 1.84;
            }

            + #{$root}__txt {
                margin: 25px 0 0;

                @include r.sp {
                    margin: 22px 0 0;
                }
            }
        }

        #{$root}__img {
            width: 45%;
            padding: 2px 0 0;

            @include r.sp {
                width: 100%;
                padding: 0;
                order: -1;
                margin: 0 0 17px;
            }
        }
    }
}

// c-imgtext4
.c-imgtext4 {
    margin-top: 18px;
    padding: 0 105px 0 100px;

    @include r.sp {
        padding: 0;
        margin-top: 32px;
    }

    &__txt {
        display: inline-block;
        font-size: 2rem;
        font-weight: 700;
        line-height: 25px;
        color: #2f304d;
        background-color: #e4e4e4;
        padding: 7px 15px 2px 16px;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 17px;
            position: relative;
            z-index: 2;
            padding: 2px 24px 1px 24px;
        }
    }

    &__img {
        @include r.sp {
            position: relative;
            top: -3px;
            z-index: 1;
        }
    }
}
