@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

// .l-header{}
.c-header {
    background: #fff;
    padding-left: 50px;
    padding-right: 32px;
    border-bottom: 1px solid #e4e4e4;
    // transition: all 0.3s ease-out;
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
    left: 0;
    min-width: 1200px;

    @include r.sp {
        padding: 17px 20px 16px;
        border-bottom: 1px solid #cccccc;
        min-width: auto;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__menu {
        display: flex;

        @include r.sp {
            flex-direction: column;
        }

        &__en {
            @include r.RobotoCondensed;
            font-size: 2.2rem;

            @include r.max(1540px) {
                font-size: 1.8rem;
            }

            @include r.sp {
                font-size: 1.6rem;
            }

            @include r.max(350px) {
                font-size: 1.5rem;
            }
        }

        &__jp {
            font-size: 1.6rem;
            font-weight: 500;

            @include r.max(1540px) {
                font-size: 1.4rem;
            }

            @include r.sp {
                font-size: 1.6rem;
            }

            @include r.max(350px) {
                font-size: 1.5rem;
            }
        }

        &__link {
            color: v.$text-color;
            line-height: 30px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 34px 47px 25px;
            height: 100%;
            position: relative;
            transition: color 0.3s ease-in-out;

            @include r.max(1540px) {
                padding: 30px;
            }

            @include r.sp {
                width: 100%;
                flex-direction: row;
                justify-content: flex-start;
                gap: 0 15px;
                padding: 19px 65px 17px 30px;
                line-height: 29px;
            }

            &:before {
                content: "";
                background-color: #fceebf;
                width: 100%;
                height: 10px;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 50;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                @include r.sp {
                    display: none;
                }
            }
        }

        &__item {
            display: flex;
            justify-content: center;
            align-items: center;

            @include r.sp {
                display: block;
                border-bottom: 1px solid #cccccc;

                &:first-child {
                    border-top: 1px solid #cccccc;

                    .c-header__submenu {
                        &__item {
                            &:nth-child(1) {
                                order: 1;
                            }

                            &:nth-child(2) {
                                order: 3;
                            }

                            &:nth-child(3) {
                                order: 2;
                            }

                            &:nth-child(4) {
                                order: 4;
                            }
                        }
                    }
                }
            }

            @include r.hover {
                .c-header__menu {
                    &__link {
                        color: #727272;

                        &:before {
                            opacity: 1;
                        }
                    }
                }

                .c-header__dropdown {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &.is-submenu {
                .c-header__menu {
                    &__btndown {
                        width: 60px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 5;

                        &:after {
                            @include r.sp {
                                content: "＋";
                                @include r.RobotoCondensed;
                                font-size: 2.2rem;
                                line-height: 29px;
                                color: v.$text-color;
                                position: absolute;
                                top: 46%;
                                transform: translateY(-50%);
                                right: 19px;
                                z-index: 2;
                                transition: content 0.3s linear;
                            }
                        }
                    }
                }
            }

            .c-header__menu {
                &__btndown {
                    &.is-show {
                        &:after {
                            @include r.sp {
                                content: "－";
                            }
                        }
                    }
                }
            }
        }
    }

    &__logo {
        @include r.max(1300px) {
            width: 300px;
        }

        @include r.sp {
            width: 200px;
        }
    }

    // c-header__submenu
    &__submenu {
        &__link {
            font-size: 2rem;
            font-weight: 500;
            line-height: 29px;
            color: #363434;
            letter-spacing: 0.58px;

            @include r.max(1540px) {
                font-size: 1.6rem;
            }

            @include r.sp {
                display: block;
                width: 100%;
                padding: 13px 30px 14px;
                line-height: 27px;
                letter-spacing: 0.432px;
                background-color: #f7f7f7;
            }

            @include r.hover {
                color: v.$color1;
            }
        }

        &__item {
            width: 100%;
            padding-bottom: 31px;

            @include r.max(1540px) {
                padding-bottom: 20px;
            }

            @include r.sp {
                padding-bottom: 0;
                border-top: 1px solid #cccccc;
            }
        }

        &__list {
            max-width: 940px;
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            margin-bottom: -31px;

            @include r.max(1540px) {
                margin-bottom: -20px;
            }

            @include r.sp {
                flex-direction: column;
                margin-bottom: 0;
            }

            &--col2 {
                justify-content: space-between;

                .c-header__submenu {
                    &__item {
                        width: 48%;

                        @include r.sp {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    // c-header__sns
    &__sns {
        &__icon {
            width: 40px;
            height: 40px;
            display: inline-block;
            margin-right: 20px;
            transition: opacity 0.3s ease-in-out;

            @include r.sp {
                width: 30px;
                height: 30px;
            }
        }

        &__txt {
            font-size: 2rem;
            font-weight: 500;
            line-height: 29px;
            color: #363434;
            transition: color 0.3s ease-in-out;

            @include r.max(1540px) {
                font-size: 1.6rem;
            }
        }

        &__link {
            display: flex;
            align-items: center;

            @include r.sp {
                width: 100%;
                padding: 13px 36px;
                background-color: #f7f7f7;
                position: relative;
            }

            @include r.hover {
                .c-header__sns {
                    &__icon {
                        opacity: 0.5;
                    }

                    &__txt {
                        color: #e9b54a;
                    }
                }
            }
        }

        &__item {
            width: 48%;
            padding-bottom: 40px;
            display: flex;

            @include r.max(1540px) {
                padding-bottom: 20px;
            }

            @include r.sp {
                display: block;
                padding-bottom: 0;
                width: 100%;
                border-top: 1px solid #cccccc;

                &--twitter {
                    order: 1;
                }

                &--facebook {
                    order: 3;
                }

                &--instagram {
                    order: 2;
                }

                &--youtube {
                    order: 4;
                }
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: -40px;

            @include r.max(1540px) {
                margin-bottom: -20px;
            }

            @include r.sp {
                flex-direction: column;
                margin-bottom: 0;
            }
        }
    }

    // c-header__dropdown
    &__dropdown {
        background-color: #f8f8f8;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        border-bottom: 1px solid #e4e4e4;
        @include r.sp {
            background-color: transparent;
            overflow: inherit;
            height: auto;
            display: none;
            opacity: 1;
            visibility: visible;
            border-bottom: none;
            // transition: all 0.5s linear;

            &.is-show {
                height: auto;
                visibility: visible;
                opacity: 1;
            }
        }

        &__heading {
            font-weight: 500;
            line-height: 40px;
            color: v.$color1;
            display: flex;
            flex-direction: column;
            padding-left: 20px;

            @include r.max(1540px) {
                line-height: 30px;
                padding-left: 0;
            }
        }

        &__en {
            font-size: 3rem;

            @include r.max(1540px) {
                font-size: 2rem;
            }
        }

        &__jp {
            font-size: 2rem;

            @include r.max(1540px) {
                font-size: 1.8rem;
            }
        }

        .c-header__submenu,
        .c-header__sns,
        &__title {
            padding-top: 57px;
            padding-bottom: 54px;

            @include r.max(1540px) {
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }

        .c-header__submenu,
        .c-header__sns {
            width: 100%;
            max-width: calc(100% - 660px);
            padding-left: 140px;
            padding-right: 40px;
            background-color: #fff;

            @include r.max(1540px) {
                padding-left: 80px;
                padding-right: 10px;
            }

            @include r.max(1300px) {
                max-width: calc(100% - 400px);
            }

            @include r.sp {
                max-width: 100%;
                padding: 0;
                background-color: transparent;

                &__link {
                    position: relative;

                    &:after {
                        content: "";
                        background: url(p.$path + "images/common/icon_arrow_yellow.svg") no-repeat center;
                        background-size: contain;
                        width: 6px;
                        height: 12px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 23px;
                        z-index: 2;
                    }
                }
            }
        }

        .c-header__sns {
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 130px;
            padding-right: 20px;

            @include r.max(1540px) {
                padding-top: 30px;
                padding-bottom: 30px;
                padding-left: 80px;
                padding-right: 10px;
            }

            @include r.sp {
                padding: 0;
            }

            &__list {
                max-width: 654px;

                @include r.sp {
                    max-width: 100%;
                }
            }
        }

        &__title {
            width: 100%;
            max-width: 660px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 200px;

            @include r.max(1300px) {
                max-width: 400px;
                padding-left: 100px;
            }

            @include r.sp {
                display: none;
            }
        }

        &__inner {
            display: flex;
            justify-content: center;
            align-items: center;

            @include r.sp {
                display: block;
            }
        }
    }

    // c-header__navbar
    &__navbar {
        width: 19px;
        height: 16px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        cursor: pointer;
        display: none;

        @include r.sp {
            display: flex;
        }

        span {
            width: 100%;
            height: 2px;
            background-color: #494949;
            margin-bottom: 5px;
            transition: all 0.3s ease-in-out;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__dropdown {
        @include r.mq(mdover) {
            position: absolute;
            width: 100%;
            left: 0;
            top: calc(100% + 1px);
            z-index: 50;
        }
    }

    &__gnav {
        @include r.sp {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #fff;
            top: 50px;
            left: 0;
            z-index: -1;
            overflow-y: auto;
            padding-bottom: 51px;
            // transform: translateX(100%);
            transition: all 0.3s ease-in-out;
            opacity: 0;
            visibility: hidden;
        }

        li {
            &:before {
                display: none;
            }
        }
    }

    &.is-sticky,
    &.is-fixed {
        position: fixed;
        width: 100%;
        z-index: 99;
        top: 0;
        left: 0;
    }

    &.is-sticky {
        min-width: 1200px;
    }

    &.is-fixed {
        height: 100%;
        border-bottom: 0;

        .c-header__gnav {
            // transform: translateX(0%);
            opacity: 1;
            visibility: visible;
            z-index: 50;
        }

        .c-header__navbar {
            width: 14px;

            span {
                position: absolute;
                top: 7px;
                right: 3px;
                z-index: 2;
                background-color: #333333;

                &:not(:first-child, :last-child) {
                    display: none;
                }

                &:first-child {
                    transform: rotate(45deg);
                }

                &:last-child {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &.is-hidden {
        // position: fixed;
        top: -120px;
        opacity: 0;
        transition: 0.3s ease;
    }

    &.is-show {
        // position: fixed;
        top: 0;
        opacity: 1;
        transition: 0.3s ease;
    }
}
