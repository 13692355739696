@use "../settings/mixin" as r;
@use "../settings/variable" as v;
@use "../settings/_path" as p;

@use "../../../node_modules/sanitize.css/sanitize.css";
@use "../../../node_modules/sanitize.css/forms.css";

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

li {
  list-style-type: none;
}

th {
  font-weight: inherit;
}

address {
  font-style: inherit;
}

fieldset {
  min-width: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

button,
input,
select,
textarea {
  padding: 0;
  border: 0;
}

button:not(:disabled):not([aria-disabled="true"]) {
  cursor: pointer;
}

html {
  font-size: 62.5%;
}

body {
  min-width: 1200px;
  height: 100vh;
  @include r.yu-gothic;
  font-weight: 400;
  font-size: 1.6rem;
  color: v.$text-color;
  width: 100%;
  padding-top: 120px;
  @include r.sp {
    padding-top: 50px;
    min-width: 320px;
  }
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
  body {
    // Safari用のハック
    height: -webkit-fill-available;
  }
}

[v-cloak] {
  visibility: hidden;
}

.l-wrapper {
  position: relative;
  overflow: hidden;
}

.l-cont {
  width: 100%;
  max-width: 1560px;
  padding: 0 20px;
  margin: 0 auto;

  @include r.sp {
    padding: 0 16px;
  }
}

.l-container1 {
  max-width: 1240px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  @include r.sp {
    padding: 0 30px;
  }

  .l-container2 {
    @include r.sp {
      padding: 0;
    }
  }
}

.l-container2 {
  max-width: 1040px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  @include r.sp {
    padding: 0 30px;
  }
}

.l-content {
  max-width: 840px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  @include r.sp {
    padding: 0 30px;
  }
}

.pc {
  @include r.sp {
    display: none !important;
  }
}

.sp {
  @include r.mq(mdover) {
    display: none !important;
  }
}

.u-bg {
  background: v.$color6;
  @include r.sp {
    background: v.$color7;
  }
}

.u-bg2 {
  background: v.$color6;
}
