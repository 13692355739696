@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
text
------------------------------------------------------------*/
.c-text {
    font-size: 1.6rem;
    line-height: 1.88;
    color: v.$color8;
    font-weight: 500;
    @include r.sp {
        font-size: 1.2rem;
        line-height: 1.83;
    }
}

/*
------------------------------------------------------------*/
.c-text2 {
    font-size: 1.6rem;
    line-height: 2.25;
    color: v.$text-color;
    font-weight: 500;
    @include r.sp {
        font-size: 1.2rem;
        line-height: 2;
    }
}
