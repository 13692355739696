@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
list
------------------------------------------------------------*/
.c-list1 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 26px;
    color: #3b3939;

    @include r.sp {
        font-size: 1.4rem;
        line-height: 22px;
        font-weight: 500;
    }

    &__banner {
        display: block;
        max-width: 510px;
        width: 100%;
        height: 170px;
        margin: 0 auto 31px;

        @include r.sp {
            max-width: 100%;
            margin-bottom: 21px;
            height: 28vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    // &__date,
    // &__label,
    // &__link {
    //     width: 100%;
    // }

    &__link {
        transition: all 0.3s ease-in-out;

        @include r.hover {
            opacity: 0.5;
        }
    }

    &__date {
        display: inline-block;
        max-width: 84px;
        width: 100%;

        @include r.sp {
            max-width: 82px;
        }

        @include r.max(330px) {
            // max-width: calc(100% - 171px);
            // margin-right: 20px;
        }
    }

    &__label {
        display: inline-block;
        color: #fff;
        width: 100%;
        max-width: 130px;
        width: 100%;
        padding: 3px 5px 1px;
        margin: 0 20px 0 10px;
        text-align: center;
        background-color: #727272;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 20px;
            max-width: 100px;
            margin-right: 0;
            margin-left: 10px;
            padding-bottom: 3px;
        }
    }

    &__title {
        text-decoration: underline;
        max-width: calc(100% - (74px + 130px));
        transition: all 0.3s ease-in-out;

        @include r.sp {
            max-width: 100%;
            width: 100%;
            margin-top: 8px;
            letter-spacing: 0.308px;
        }
    }

    &__txt {
        display: flex;
        align-items: flex-start;

        @include r.sp {
            flex-wrap: wrap;
            align-items: center;
        }

        @include r.max(350px) {
            // padding-right: 19px;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 20px;

            @include r.sp {
                margin-bottom: 31px;
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-list2 {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    max-width: 542px;
    margin: 0 auto;

    @include r.sp {
        max-width: 126px;
    }

    &__link {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 50px;
        color: v.$text-color;
        text-decoration: underline;
        position: relative;
        padding-left: 21px;

        @include r.sp {
            font-size: 1.4rem;
            line-height: 1.2;
            padding-left: 14px;
        }

        &:before {
            content: "・";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            z-index: 2;
            text-decoration: none;
        }

        @include r.hover {
            color: #727272;
        }
    }

    &__item {
        width: 50%;

        @include r.sp {
            width: 100%;
            margin-bottom: 14px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:nth-child(even) {
            padding-left: 94px;

            @include r.sp {
                padding-left: 0;
            }
        }
    }

    &__subitem {
        &:not(:last-child) {
            @include r.sp {
                margin: 0 0 14px;
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-list3 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -50px;
    $root: &;

    @include r.sp {
        margin: 0;

        &:not(:first-child) {
            border-top: 1px solid #e4e4e4;
            padding-top: 39px;
            margin-top: 39px;
        }
    }

    //-----------------------------------------------------
    &__item {
        width: calc((100% / 2) - 100px);
        margin: 0 50px;
        margin-bottom: 56px;
        position: relative;

        @include r.sp {
            width: 100%;
            margin: 0;
        }

        @include r.hover {
            #{$root}__ttl {
                a {
                    &::after {
                        background: rgba($color: #fff, $alpha: 0.5);
                    }
                }
            }
        }

        &:not(:first-child) {
            @include r.sp {
                border-top: 1px solid v.$color9;
                padding: 39px 0 0;
                margin: 39px 0 0;
            }
        }
    }

    //-----------------------------------------------------
    &__inner {
        height: 100%;

        @include r.sp {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    //-----------------------------------------------------
    &__intro {
        width: 100%;
        height: 255px;
        border: 1px solid v.$color10;
        box-sizing: border-box;

        @include r.sp {
            width: 44.5%;
            height: 21.4vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    //-----------------------------------------------------
    &__info {
        margin: 20px 0 0;
        padding: 0 20px;

        @include r.sp {
            width: 49.3%;
            margin: 0;
            padding: 0;
        }
    }

    //-----------------------------------------------------
    &__detail {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @include r.sp {
            justify-content: flex-start;
        }
    }

    //-----------------------------------------------------
    &__time {
        display: block;
        font-size: 2rem;
        line-height: 1.3;
        color: #727272;
        font-weight: bold;
        margin: 0 30px 0 0;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 1;
            margin: 0 10px 0 0;
        }

        @include r.max(350px) {
            margin-bottom: 10px;
        }
    }

    .c-tag1 {
        min-width: 130px;

        @include r.sp {
            min-width: 70px;
        }
    }

    //-----------------------------------------------------
    &__ttl {
        text-align: center;
        font-size: 2rem;
        line-height: 1.3;
        color: v.$text-color;
        font-weight: bold;
        margin: 8px 0 0;

        @include r.sp {
            text-align: left;
            font-size: 1.2rem;
            line-height: 1.5;
            margin: 9px 0 0;
        }

        a {
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-list4 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
    $root: &;

    @include r.sp {
        margin: 0;

        &:not(:first-child) {
            border-top: 1px solid #e4e4e4;
            padding-top: 39px;
            margin-top: 39px;
        }
    }

    //-----------------------------------------------------
    &__item {
        width: calc((100% / 2) - 50px);
        margin: 0 25px;
        margin-bottom: 9px;
        position: relative;

        @include r.sp {
            width: 100%;
            margin: 0;
        }

        @include r.hover {
            #{$root}__ttl {
                a {
                    &::after {
                        background: rgba($color: #fff, $alpha: 0.5);
                    }
                }
            }
        }

        &:not(:first-child) {
            @include r.sp {
                border-top: 1px solid v.$color9;
                padding: 39px 0 0;
                margin: 39px 0 0;
            }
        }
    }

    //-----------------------------------------------------
    &__inner {
        height: 100%;

        @include r.sp {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    //-----------------------------------------------------
    &__img {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            padding-top: 56.25%;
            display: block;
        }

        @include r.sp {
            width: 44.5%;
            height: 21.4vw;
        }

        img,
        iframe {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;

            @include r.sp {
                object-fit: contain;
            }
        }

        iframe {
            min-width: auto;
        }
    }

    //-----------------------------------------------------
    &__info {
        margin: 20px 0 0;

        @include r.sp {
            width: 49.3%;
            margin: 0;
        }
    }

    //-----------------------------------------------------
    &__detail {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    //-----------------------------------------------------
    &__time {
        display: block;
        font-size: 1.6rem;
        line-height: 1.3;
        color: #727272;
        font-weight: bold;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 1;
        }
    }

    .c-tag1 {
        min-width: 130px;
        margin: 0 20px 0 0;

        @include r.sp {
            min-width: 70px;
            margin: 0 10px 0 0;
        }

        @include r.max(350px) {
            margin-bottom: 10px;
        }
    }

    //-----------------------------------------------------
    &__ttl {
        font-size: 1.6rem;
        line-height: 1.88;
        color: #657bb5;
        font-weight: bold;
        margin: 17px 0 0;

        @include r.sp {
            text-align: left;
            font-size: 1.2rem;
            line-height: 1.5;
            margin: 9px 0 0;
        }

        a {
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-list5 {
    $root: &;

    //-----------------------------------------------------
    &__item {
        &:not(.c-list5__item--nohover) {
            @include r.hover {
                #{$root}__link {
                    &::after {
                        background: rgba($color: #fff, $alpha: 0.5);
                    }
                }
            }

            #{$root}__link {
                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: all 0.3s ease-in-out;
                }
            }
        }

        &:not(:first-child) {
            @include r.sp {
                border-top: 1px solid v.$color9;
                padding: 39px 0 0;
                margin: 39px 0 0;
            }
        }
    }

    //-----------------------------------------------------
    &__link {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
    }

    //-----------------------------------------------------
    &__img {
        width: 30%;
        height: 140px;

        @include r.sp {
            width: 42.5%;
            height: 21.4vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    //-----------------------------------------------------
    &__info {
        width: 68%;

        @include r.sp {
            width: 52.5%;
        }
    }

    //-----------------------------------------------------
    &__tag {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 10px;

        @include r.sp {
            margin: 0 0 5px;
        }

        .c-tag1 {
            margin: 0 0 0 30px;

            @include r.sp {
                margin: 0 0 0 10px;
            }
        }
    }

    //-----------------------------------------------------
    &__date {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1;
        color: v.$color8;

        @include r.sp {
            font-size: 1.2rem;
        }
    }

    //-----------------------------------------------------
    &__ttl {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 26px;
        color: v.$color8;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 22px;
        }
    }
}