@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

// c-footer
.c-footer {
    background-color: #000000;
    color: #fff;

    @include r.sp {
        padding-top: 40px;
        padding-bottom: 38px;
    }

    &__title {
        width: 389px;

        @include r.sp {
            width: 100%;
            max-width: 310px;
        }

        img {
            width: 100%;
        }
    }

    &__desc,
    &__copyright {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 20px;
        margin-left: -6px;

        @include r.sp {
            margin-left: 0;
            font-size: 1rem;
            line-height: 16px;
        }
    }

    &__desc {
        margin-top: 26px;
        margin-bottom: 21px;

        @include r.sp {
            margin-top: 30px;
            margin-bottom: 16px;
        }
    }

    &__link {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 22px;
        transition: all 0.3s ease-in-out;

        @include r.sp {
            font-size: 1rem;
            line-height: 16px;
            display: inline-block;
        }

        @include r.hover {
            opacity: 0.7;
        }
    }

    &__left {
        margin-left: -53px;
        max-width: 397px;
        width: 100%;

        @include r.sp {
            max-width: 100%;
            margin-left: 0;
        }
    }

    &__right {
        padding-top: 26px;
        max-width: 284px;
        width: 100%;

        @include r.sp {
            max-width: 100%;
            padding-top: 29px;
        }
    }

    &__inner {
        display: flex;
        gap: 0 119px;
        padding-top: 41px;
        padding-bottom: 33px;
        max-width: 806px;
        margin: 0 auto;

        @include r.sp {
            gap: 0;
            display: block;
            padding: 0 3px;
            max-width: 100%;
        }
    }
}

.txt-indent {
    @include r.sp {
        margin-left: 50px;
    }
}

.c-scrollToTop {
    width: 80px;
    height: 80px;
    background-color: rgba($color: #6f7070, $alpha: 0.5);
    border-radius: 100%;
    position: fixed;
    bottom: 80px;
    right: 240px;
    z-index: 50;
    font-size: 0;
    display: none;

    @include r.max(1540px) {
        right: 80px;
    }

    @include r.max(1200px) {
        right: 30px;
    }
    @include r.sp {
        width: 40px;
        height: 40px;
        right: 10px;
        bottom: 30px;
    }

    &:before {
        content: "";
        border-bottom-style: solid;
        border-bottom-width: 3px;
        border-right-style: solid;
        border-right-width: 3px;
        border-color: #fff;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 50%;
        top: 64%;
        transform: translate(-50%, -50%) rotate(225deg);
        @include r.sp {
            width: 12px;
            height: 12px;
            border-bottom-width: 2px;
            border-right-width: 2px;
            top: 60%;
        }
    }
}
