@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

// c-title1
.c-title1 {
    @include r.RobotoCondensed();
    font-size: 3rem;
    font-weight: 700;
    line-height: 39px;
    color: #3b3939;
    text-align: center;
    text-transform: uppercase;

    @include r.sp {
        font-size: 2.2rem;
        line-height: 29px;
    }

    span {
        border-bottom: 3px solid #fabd00;
        padding-bottom: 10px;

        @include r.sp {
            border-bottom-width: 2px;
            padding-bottom: 0;
        }
    }
}

// c-title2
.c-title2 {
    font-weight: 700;
    line-height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    $root: &;
    @include r.sp {
        line-height: 1.5;
    }

    &__en {
        font-size: 2.2rem;
        color: v.$color2;

        @include r.sp {
            font-size: 1.6rem;
        }
    }

    &__jp {
        font-size: 2.8rem;
        color: v.$text-color;

        @include r.sp {
            font-size: 1.6rem;
        }
    }

    &--style2 {
        @include r.sp {
            line-height: 1.3;
        }

        #{$root}__jp {
            @include r.sp {
                font-size: 1.8rem;
            }
        }
    }
}

// c-title3
.c-title3 {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.9;
    text-align: center;
    color: v.$text-color;

    @include r.sp {
        font-size: 1.8rem;
        line-height: 1.67;
    }

    span {
        border-bottom: 3px solid #fabd00;
        padding: 0 0 5px;

        @include r.sp {
            padding: 0;
        }
    }

    &--gray {
        line-height: 30px;

        span {
            border-bottom: 2px solid #e3e4e4;
        }
    }
}

// c-title4
.c-title4 {
    position: relative;

    &:before {
        content: "";
        background-color: #e4e4e4;
        width: 100%;
        height: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 1;

        @include r.sp {
            display: none;
        }
    }

    span {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 36px;
        color: #3b3939;
        background-color: #e4e4e4;
        padding: 4px 29px 2px 30px;
        text-align: center;
        max-width: 410px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        @include r.sp {
            max-width: 400px;
            font-size: 1.6rem;
            line-height: 26px;
            padding: 8px 10px 6px;
        }
    }
}
