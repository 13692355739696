@use "./settings/variable" as v;
@use "./settings/mixin" as r;
@use "./settings/_path" as p;

/*------------------------------------------------------------
Editor Wp-content
------------------------------------------------------------*/
#tinymce,
.c-content {
    p {
        font-size: 16px;
        line-height: 1.88;
        color: v.$color8;
        font-weight: 500;

        @include r.sp {
            font-size: 12px;
            line-height: 1.83;
        }
    }

    h3,
    h4,
    h5,
    h6 {
        font-size: 20px;
        line-height: 1.5;
        color: v.$color8;
        font-weight: bold;
        border-left: 5px solid v.$color11;
        padding: 0 0 0 7px;

        @include r.sp {
            font-size: 18px;
            line-height: 1.68;
        }
    }

    iframe {
        width: 100%;
        height: 500px;

        @include r.sp {
            height: 400px;
        }

        @include r.max(480px) {
            height: 179px;
        }
    }
}

#tinymce {
    span {
        &.mce-preview-object[data-mce-object="iframe"] {
            display: block;
        }
    }
}