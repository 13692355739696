@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
mv
------------------------------------------------------------*/
.c-mv1 {
    background: url(p.$path + "/images/top/mv_bg.png") no-repeat center / cover;
    padding: 29px 20px 109px;
    @include r.sp {
        padding: 40px 10px 30px;
    }

    &__inner {
        max-width: 1572px;
        width: 100%;
        margin: 0 auto;
    }

    &__content {
        display: flex;
        flex-wrap: wrap-reverse;
        align-items: center;
        @include r.sp {
            flex-direction: column-reverse;
            align-items: flex-start;
            position: relative;
        }
    }

    &__info {
        width: 50%;
        margin: 0 2% 0 0;
        padding: 63px 0 0 26px;
        @include r.sp {
            width: auto;
            padding: 0;
            margin: 95px 7px 0 0;
        }
    }

    &__img {
        width: 43.1%;
        display: flex;
        justify-content: space-between;
        @include r.sp {
            width: 62.5%;
        }
        &main {
            width: 67%;
            margin: 59px 0 0;
            @include r.sp {
                margin: 20px 0 0;
            }
        }
    }

    &__logo {
        width: 26%;
    }

    &__desc {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1.5;
        color: v.$color5;
        margin-top: 90px;
        max-width: 540px;
        @include r.sp {
            text-align: right;
            font-size: 1.3rem;
            line-height: 22px;
            margin-top: 0;
            max-width: 100%;
        }

        .u-small {
            display: block;
            font-size: 2rem;
            line-height: 36px;

            @include r.sp {
                font-size: 1.2rem;
                line-height: 22px;
            }
        }
    }

    &__title {
        max-width: 100%;
        width: 636px;

        @include r.sp {
            width: 20.8%;
            max-width: 130px;
            position: absolute;
            top: -6px;
            left: 9.5%;
            z-index: 2;
        }

        @include r.max(350px) {
            left: 7%;
        }

        img {
            width: 100%;

            @include r.sp {
                max-height: 420px;
            }
        }
    }

    &__btn {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 0 60px;
        margin: 83px 0 0;

        @include r.sp {
            gap: 20px 0;
            flex-direction: column;
            bottom: 0;
            position: relative;
            left: 0;
            transform: none;
            margin-top: 26px;
            padding: 0 20px;
        }

        .c-btn1 {
            &:first-child {
                padding-left: 70px;

                @include r.mq(lgless) {
                    padding-left: 15px;
                }
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-mv2 {
    background: v.$color6;
    padding: 53px 0 50px;

    @include r.sp {
        padding: 30px 0 27px;
    }

    &--white {
        background: v.$color7;

        @include r.sp {
            background: v.$color6;
        }
    }
}

/*
------------------------------------------------------------*/
.c-mv3 {
    padding-top: 52px;
    padding-bottom: 60px;
    background-color: #f7f7f7;

    @include r.sp {
        background-color: #f5f5f5;
        padding-top: 29px;
        padding-bottom: 41px;
    }

    .c-title2 {
        margin-bottom: 55px;

        @include r.sp {
            margin-bottom: 27px;

            &__jp {
                margin-top: 0;
            }
        }
    }

    &__inner {
        padding: 0 134px;

        @include r.sp {
            padding: 0;
        }
    }
}

/*
------------------------------------------------------------*/
.c-mv4 {
    background: v.$color6;
    padding: 52px 0 51px;

    @include r.sp {
        padding: 27px 0 24px;
    }

    //-----------------------------------------------------
    &__inner {
        max-width: 1040px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;

        @include r.sp {
            max-width: 100%;
        }
    }

    //-----------------------------------------------------
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 84px 0 0;

        @include r.sp {
            max-width: 210px;
            margin: 46px auto 0;
        }
    }

    //-----------------------------------------------------
    &__item {
        width: calc((100% - 100px) / 3);

        @include r.sp {
            width: 100%;
        }

        &:not(:last-child) {
            @include r.sp {
                margin: 0 0 31px;
            }
        }
    }

    //-----------------------------------------------------
    &__link {
        display: block;
        height: 100%;
        background: v.$color7;
        text-align: center;
        position: relative;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
        padding: 27px 10px 31px;

        @include r.sp {
            padding: 16px 5px 23px;
        }

        &::after {
            content: "";
            width: 20px;
            height: 10px;
            background: url(p.$path + "images/common/icon-arrow-down.svg") no-repeat center;
            position: absolute;
            left: 50%;
            bottom: 10px;
            transform: translate(-50%, 0);

            @include r.sp {
                width: 14px;
                height: 9px;
                bottom: 8px;
            }
        }
    }

    //-----------------------------------------------------
    &__number {
        width: 100%;
        font-size: 4rem;
        line-height: 1;
        color: v.$color2;
        font-weight: 500;
        @include r.Roboto;
        position: absolute;
        top: -22px;
        left: 0;

        @include r.sp {
            font-size: 2.8rem;
            top: -16px;
        }
    }

    //-----------------------------------------------------
    &__ttlsub {
        display: block;
        font-size: 1.4rem;
        line-height: 1.5;
        color: v.$text-color;
        font-weight: bold;

        @include r.sp {
            font-size: 1.2rem;
        }
    }

    //-----------------------------------------------------
    &__ttlmain {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        line-height: 1.35;
        color: v.$text-color;
        font-weight: bold;
        margin: 12px 0 0;

        @include r.sp {
            min-height: 33px;
            font-size: 1.4rem;
            line-height: 1.1;
            margin: 8px 0 0;
        }
    }

    //-----------------------------------------------------
    &__txt {
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.42;
        color: v.$color8;
        font-weight: 500;
        margin: 57px 0 0;

        @include r.sp {
            font-size: 1rem;
            line-height: 2;
            margin: 26px 0 0;
        }
    }
}
