@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
btn
------------------------------------------------------------*/
.c-btn1 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 26px;
    color: #fff;
    background-color: #000000;
    border: 1px solid #707070;
    width: 100%;
    max-width: 390px;
    padding: 18px 30px 14px 19px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transition: all 0.3s ease-in-out;

    @include r.max(1300px) {
        font-size: 1.4rem;
    }

    @include r.sp {
        max-width: 100%;
        font-size: 1.4rem;
        line-height: 22px;
        padding: 12px 30px 12px 15px;
        justify-content: center;
        letter-spacing: 0.168px;

        span {
            font-size: 1.2rem;
            line-height: 20px;
        }
    }

    @include r.max(350px) {
        font-size: 1.2rem;

        span {
            font-size: 1rem;
        }
    }

    &:after {
        content: "";
        background: url(p.$path + "images/common/icon_arrow_yellow.svg") no-repeat center;
        background-size: contain;
        width: 6px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 19px;
        z-index: 2;
    }

    @include r.hover {
        background-color: #fff;
        color: v.$text-color;
    }

    //-----------------------------------------------------
    &--white {
        background: #fff;
        color: v.$text-color;

        @include r.hover {
            background: #000;
            color: v.$color7;
        }
    }
}

/*
------------------------------------------------------------*/
.c-btn2 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 26px;
    color: #3b3939;
    background-color: #fff;
    border: 1px solid #707070;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease-in-out;
    max-width: 255px;
    width: 100%;
    padding: 11px 35px 11px 7px;
    margin: 0 auto;

    @include r.sp {
        font-size: 1.4rem;
        max-width: 200px;
        padding: 6px 35px 6px 7px;
    }

    &:after {
        content: "";
        background: url(p.$path + "images/common/icon_arrow_yellow.svg") no-repeat center;
        background-size: contain;
        width: 6px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        z-index: 2;

        @include r.sp {
            right: 14px;
        }
    }

    @include r.hover {
        color: #727272;
    }
}

/*
------------------------------------------------------------*/
.c-btn3 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 30px;
    color: v.$text-color;
    border: 1px solid #707070;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fff;
    padding: 10px 35px 8px 17px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    width: 100%;

    @include r.sp {
        font-size: 1.2rem;
        line-height: 20px;
        max-width: 300px;
        padding: 13px 33px 13px 5px;
        letter-spacing: 0;
    }

    &:after {
        content: "";
        background: url(p.$path + "images/common/icon_arrow_yellow.svg") no-repeat center;
        background-size: contain;
        width: 6px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        z-index: 2;

        @include r.sp {
            right: 19px;
        }
    }

    span {
        font-size: 1.2rem;

        @include r.sp {
            font-size: 1.1rem;
        }
    }

    &--w {
        &1 {
            max-width: 380px;

            @include r.sp {
                max-width: 300px;
            }
        }

        &2 {
            max-width: 327px;

            @include r.sp {
                max-width: 300px;
            }
        }

        &3 {
            max-width: 250px;

            @include r.sp {
                max-width: 300px;
            }
        }

        &4 {
            max-width: 340px;

            @include r.sp {
                max-width: 280px;
            }
        }
    }

    &--center {
        @include r.sp {
            padding-left: 30px;
        }
    }

    @include r.hover {
        background-color: #000000;
        color: #fff;
    }
}

/*
------------------------------------------------------------*/
.c-btn4 {
    max-width: 100%;
    width: 255px;
    margin: 0 auto;
    padding: 0;
    $root: &;

    @include r.sp {
        width: 200px;
    }

    //-----------------------------------------------------
    &__txt {
        display: block;
        text-align: center;
        background: #000000;
        border: 1px solid v.$color10;
        box-sizing: border-box;
        font-size: 1.6rem;
        line-height: 1.5;
        color: #ffffff;
        font-weight: bold;
        position: relative;
        padding: 13px 40px 11px 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        @include r.sp {
            background: #fff;
            font-size: 1.4rem;
            color: v.$color8;
            padding: 9px 35px 8px 10px;
        }

        &::after {
            content: "";
            width: 13px;
            height: 2px;
            background: v.$color2;
            position: absolute;
            top: 50%;
            right: 18px;
            transform: translate(0, -50%);
            transition: all 0.3s ease-in-out;

            @include r.sp {
                right: 9px;
            }
        }

        &::before {
            content: "";
            width: 2px;
            height: 13px;
            background: v.$color2;
            position: absolute;
            top: 50%;
            right: 23px;
            transform: translate(0, -50%);
            transition: all 0.3s ease-in-out;

            @include r.sp {
                right: 14px;
            }
        }

        @include r.hover {
            background: v.$color7;
            color: v.$text-color;
        }
    }

    //-----------------------------------------------------
    &.is-close {
        #{$root}__txt {
            &::before {
                transform: translate(0, -50%) rotate(90deg);
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-btn5 {
    width: 255px;
    max-width: 100%;
    margin: 0 auto;

    &__txt {
        display: block;
        width: 100%;
        text-align: center;
        background: v.$color7;
        border: 1px solid v.$color10;
        box-sizing: border-box;
        font-size: 1.6rem;
        line-height: 1.3;
        color: v.$color8;
        font-weight: bold;
        position: relative;
        padding: 12px 30px 9.5px 11px;
        transition: all 0.3s ease-in-out;

        @include r.sp {
            font-size: 1.4rem;
            line-height: 1.25;
            padding: 14px 33px 12.5px 10px;
        }

        &::after {
            content: "";
            width: 6px;
            height: 12px;
            background: url(p.$path + "images/common/icon_arrow_yellow.svg") no-repeat center;
            position: absolute;
            top: 50%;
            right: 19px;
            transform: translate(0, -50%);
        }

        span {
            display: block;
            font-size: 1.2rem;
            font-weight: 500;

            @include r.sp {
                font-size: 1.1rem;
            }
        }

        @include r.hover {
            background: #000000;
            color: #fff;
        }
    }
}

/*
------------------------------------------------------------*/
/* solid007 */
.c-btn6 {
    text-align: center;

    &__txt {
        display: inline-block;
        margin-bottom: 5px;
        font-size: 2.8rem;
        line-height: 1.5;
        font-weight: bold;
        color: v.$text-color;
        position: relative;
        margin: 0 0 15px;

        @include r.sp {
            font-size: 1.8rem;
        }

        &::before,
        &::after {
            content: "";
            display: inline-block;
            width: 30px;
            height: 3px;
            border-radius: 5px;
            background-color: v.$text-color;
            position: absolute;
            top: 48%;

            @include r.sp {
                width: 24px;
            }
        }

        &::before {
            left: -45px;
            transform: rotate(50deg);

            @include r.sp {
                left: -35px;
            }
        }

        &:after {
            right: -45px;
            transform: rotate(-50deg);

            @include r.sp {
                right: -35px;
            }
        }
    }

    &__link {
        max-width: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: v.$color2;
        border-radius: 8px;
        box-shadow: 5px 5px 10px rgb(0 0 0 / 25%);
        font-size: 2.8rem;
        line-height: 1.3;
        color: v.$text-color;
        padding: 20px 42px;
        font-weight: bold;
        transition: 0.3s ease-in-out;
        filter: drop-shadow(0px 2px 4px #ccc);
        position: relative;

        @include r.sp {
            max-width: 265px;
            padding: 10px 20px;
            font-size: 1.8rem;
        }

        @include r.hover {
            transform: translateY(-2px);
            box-shadow: 0 15px 30px -5px rgb(0 0 0 / 15%), 0 0 5px rgb(0 0 0 / 10%);
        }

        &::after {
            content: "";
            width: 16px;
            height: 16px;
            border-top: 3px solid v.$text-color;
            border-right: 3px solid v.$text-color;
            position: absolute;
            top: 50%;
            right: 19px;
            transform: translate(0, -50%) rotate(45deg);

            @include r.sp {
                width: 8px;
                height: 8px;
                right: 10px;
                border-top: 2px solid v.$text-color;
                border-right: 2px solid v.$text-color;
            }
        }
    }
}