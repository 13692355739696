@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
other
------------------------------------------------------------*/
.c-tag1 {
    display: inline-block;
    text-align: center;
    background: #727272;
    font-size: 1.6rem;
    line-height: 1.6;
    color: #ffffff;
    font-weight: bold;
    padding: 3px 9px 2px;

    @include r.sp {
        font-size: 8px;
        line-height: 1.3;
        padding: 1px 7px 0.5px;
    }

    &--color2 {
        background: #b1b1b1;
    }
}

/*
------------------------------------------------------------*/
.c-anchor1 {
    @include r.sp {
        margin: 0 -15px;
    }

    &__title {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 24px;
        color: v.$text-color;

        @include r.sp {
            font-size: 1rem;
            line-height: 14px;
            letter-spacing: 0;
        }

        @include r.max(374px) {
            br {
                display: none;
            }
        }
    }

    &__icon {
        width: 80px;
        height: 80px;

        @include r.sp {
            width: 42px;
            height: 42px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__txt {
        padding-top: 17px;

        @include r.sp {
            padding-top: 8px;
        }
    }

    &__link {
        background-color: #fff;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.16);
        text-align: center;
        padding: 20px 20px 31px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        @include r.sp {
            padding: 10px 2px 15px 1px;
        }

        &:before {
            content: "";
            background: url(p.$path + "images/common/icon-arrow-down.svg") no-repeat center;
            background-size: contain;
            width: 20px;
            height: 10px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 15px;
            z-index: 2;

            @include r.sp {
                width: 10px;
                height: 6px;
                bottom: 9px;
            }
        }

        &--pd {
            @include r.min(1920px) {
                padding: 20px 12px 53px;
            }

            @include r.minmax(768px, 1919px) {
                padding: 20px 12px 53px;
            }
        }
    }

    &__item {
        width: calc(100% / 3);
        padding: 0 33px 30px;

        @include r.sp {
            padding: 0 7px 15px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin: 0 -33px -30px;

        @include r.sp {
            margin: 0 -7px -15px;
        }
    }
}

/*
------------------------------------------------------------*/
.u-bora100 {
    border-radius: 100px;

    @include r.sp {
        border-radius: 30px;
    }
}

/*
------------------------------------------------------------*/
.u-boxshow1 {
    box-shadow: 0 0 10px rgba($color: v.$text-color, $alpha: 0.16);
}

/*
------------------------------------------------------------*/
.u-bgyellow {
    background-color: #fceebf;
}

/*
------------------------------------------------------------*/
.u-bgwhite {
    background-color: #fff;
}

/* .u-border1 {
    border-bottom: 2px solid #e4e4e4;
} */

/*
------------------------------------------------------------*/
.u-bggray {
    background-color: #f7f7f7;
}

/*
------------------------------------------------------------*/
.c-block1 {
    &__label {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 25px;
        color: #fff;
        background-color: #2f304d;
        padding: 3px 20px 2px;

        @include r.sp {
            margin-bottom: 27px;
            margin-top: -15px;
        }
    }

    &__txt {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 25px;
        color: #2f304d;
        padding-left: 20px;

        @include r.sp {
            font-size: 1.8rem;
            padding-left: 0;
        }
    }

    &__title {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        background-color: #fff;
        padding-left: 21px;
        padding-right: 21px;

        @include r.sp {
            flex-direction: column;
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            background-color: transparent;
            padding: 0;
            margin-bottom: 23px;
            border-top: 2px solid #2f304d;
        }

        &--reverse {
            flex-direction: row-reverse;

            @include r.sp {
                flex-direction: column-reverse;
            }
        }
    }

    &__p {
        margin-bottom: 30px;

        @include r.sp {
            margin-bottom: 22px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__desc {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 30px;
        color: v.$text-color;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 22px;
            letter-spacing: 0;
        }
    }

    .c-btn {
        &3 {
            margin-top: 21px;

            @include r.sp {
                margin-top: 34px;
            }
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 74px;

            @include r.sp {
                margin-bottom: 62px;
            }
        }
    }

    &__inner {
        border: 2px solid #2f304d;
        border-radius: 50px;
        padding: 49px 35px 28px 45px;
        position: relative;

        @include r.sp {
            border: none;
            border-radius: 0;
            padding: 0;
        }
    }
}

/*
------------------------------------------------------------*/
.c-block2 {
    .c-title3 {
        margin-bottom: 28px;
    }

    &__desc {
        font-size: 1.6rem;
        line-height: 30px;
        font-weight: 500;
        color: v.$text-color;
        margin-bottom: 13px;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 22px;
        }
    }

    &__img {
        padding: 0 14px 0 3px;

        @include r.sp {
            padding: 0;

            img {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-block3 {
    padding-bottom: 30px;
    padding-left: 46px;
    padding-right: 60px;

    @include r.sp {
        border-bottom: 0;
        padding: 0;
    }

    &__txt {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 30px;
        color: v.$text-color;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 22px;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 30px;

                @include r.sp {
                    margin-bottom: 22px;
                }
            }
        }
    }

    .c-btn {
        &3 {
            margin-top: 19px;

            @include r.sp {
                margin-top: 30px;
            }
        }
    }

    &__img {
        width: 300px;

        @include r.sp {
            width: 100%;
            max-width: 250px;
            margin: 0 auto;
            margin-top: 23px;
        }
    }

    &__txt {
        width: calc(100% - 300px);

        @include r.sp {
            width: 100%;
            max-width: 400px;
            margin: auto;
        }
    }

    &__inner {
        display: flex;

        @include r.sp {
            display: block;
        }
    }
}

/*
------------------------------------------------------------*/
.c-block4 {
    &__title {
        font-weight: 700;
        line-height: 30px;
        color: #3b3939;

        @include r.sp {
            line-height: 24px;
            display: flex;
            flex-direction: column;
        }

        &JP {
            font-size: 2.4rem;

            @include r.sp {
                font-size: 2rem;
            }

            @include r.max(374px) {
                font-size: 1.4rem;
            }
        }

        &EN {
            font-size: 1.6rem;

            @include r.sp {
                font-size: 1.4rem;
            }

            @include r.max(374px) {
                font-size: 1.2rem;
            }
        }
    }

    &__desc {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 30px;
        color: #727272;
        margin-top: 7px;

        @include r.sp {
            font-size: 1rem;
            line-height: 20px;
            margin-top: 0;
            padding-right: 1px;
        }
    }

    &__content {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 36px;
        color: #3b3939;
        padding-top: 44px;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 22px;
            padding-top: 21px;
        }
    }

    &__txt {
        width: calc(100% - 214px);
        padding-right: 26px;
        padding-top: 56px;

        @include r.sp {
            width: calc(100% - 150px);
            padding-right: 30px;
            padding-top: 7px;
        }

        @include r.max(374px) {
            width: calc(100% - 100px);
            padding-right: 15px;
            padding-top: 0;
        }
    }

    &__img {
        width: 214px;
        height: 214px;

        @include r.sp {
            width: 150px;
            height: 150px;
        }

        @include r.max(374px) {
            width: 100px;
            height: 100px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__wrap {
        display: flex;
        padding-right: 30px;

        @include r.sp {
            align-items: center;
            padding-right: 0;
            margin-bottom: 16px;
        }
    }

    &__item {
        background-color: #f7f7f7;
        padding: 40px 100px 30px;

        @include r.sp {
            padding: 20px 20px 30px;
        }

        &:not(:last-child) {
            margin-bottom: 60px;

            @include r.sp {
                margin-bottom: 50px;
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-accordion1 {
    margin-top: 28px;

    @include r.sp {
        margin: 0 -8px;
        margin-top: 24px;
    }

    &__title {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 30px;
        color: #3b3939;
        text-align: center;
        padding: 15px 18px 11px 32px;
        cursor: pointer;
        background-color: #fff;

        @include r.sp {
            padding-bottom: 12px;
        }

        span {
            position: relative;

            &:before {
                content: "";
                background: url(p.$path + "images/common/icon_arrow_yellow.svg") no-repeat center;
                background-size: contain;
                width: 6px;
                height: 12px;
                position: absolute;
                top: 43%;
                transform: translateY(-50%);
                transition: transform 0.2s linear;
                left: -17px;
                z-index: 2;

                @include r.sp {
                    // transform: translateY(-50%) rotate(90deg);
                }
            }
        }

        &.is-show {
            span {
                &:before {
                    transform: translateY(-50%) rotate(90deg);
                }
            }
        }
    }

    &__desc {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 30px;
        color: #3b3939;
        padding-right: 25px;
        padding-left: 32px;
        padding-bottom: 7px;
        background-color: #fff;
        // visibility: hidden;
        // opacity: 0;
        // transition: visibility 0s linear 0.2s, opacity 0.2s linear;
        display: none;

        @include r.sp {
            // visibility: visible;
            // opacity: 1;
            font-size: 1.2rem;
            line-height: 20px;
            padding: 0 16px 11px;
        }

        p {
            position: relative;
            top: -4px;
        }

        &.is-show {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
            display: block;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

/*
------------------------------------------------------------*/
.c-article1 {
    background-color: #fff;
    overflow: hidden;
    font-size: 1.6rem;
    line-height: 30px;
    font-weight: 500;
    color: v.$text-color;

    @include r.sp {
        font-size: 1.2rem;
        line-height: 22px;
        letter-spacing: 0;
        margin: 0 -15px;
    }

    .c-title {
        &3 {
            margin-bottom: 23px;

            @include r.sp {
                margin-bottom: 22px;
            }
        }

        &4 {
            margin-bottom: 24px;

            @include r.sp {
                margin-bottom: 27px;
            }
        }
    }

    &__h3 {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 39px;
        color: v.$text-color;
        margin-bottom: 40px;
        text-align: center;

        @include r.sp {
            font-size: 1.6rem;
            line-height: 26px;
            margin-bottom: 22px;
            padding: 0 6px;
        }

        span {
            display: block;
            font-size: 2rem;
            line-height: 32px;

            @include r.sp {
                display: inline;
                font-size: 1.3rem;
                line-height: 26px;
            }
        }
    }

    &__h31 {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 40px;
        color: #3b3939;
        margin-bottom: 4px;

        @include r.sp {
            font-size: 1.4rem;
            line-height: 22px;
            margin-bottom: 11px;
        }
    }

    &__para {
        font-weight: 500;
        line-height: 30px;
        color: v.$text-color;
        margin-bottom: 16px;
        font-size: 1.6rem;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 22px;
            letter-spacing: 0;
            margin-bottom: 0;
        }

        &.u-para1 {
            font-size: 1.2rem;
            line-height: 20px;

            @include r.sp {
                font-size: 1rem;
                line-height: 16px;
            }
        }

        &.u-mb {
            &1 {
                margin-bottom: 12px;

                @include r.sp {
                    margin-bottom: 0;
                }
            }

            &2 {
                margin-bottom: 14px;

                @include r.sp {
                    margin-bottom: 23px;
                }
            }

            &3 {
                margin-bottom: 22px;

                @include r.sp {
                    margin-bottom: 3px;
                }
            }
        }
    }

    &__img {
        &1 {
            margin-left: -30px;
            margin-right: -22px;
            margin-bottom: 17px;

            @include r.sp {
                margin-left: -20px;
                margin-right: -20px;
                margin-bottom: 20px;
            }
        }

        &2 {
            max-width: 700px;
            margin: 0 auto 24px;
            margin-right: 42px;

            @include r.sp {
                max-width: 100%;
                margin: 0;
                margin-bottom: 28px;
            }
        }

        &3 {
            margin-left: -97px;
            margin-right: -7px;
            margin-bottom: 24px;

            @include r.sp {
                margin: 0 -20px 7px;
            }
        }

        &5 {
            margin-left: -18px;
            display: flex;
            align-items: stretch;

            @include r.sp {
                display: block;
                margin: 0 -13px;
            }

            img {
                &:nth-child(1) {
                    width: 500px;

                    @include r.sp {
                        width: 100%;
                    }
                }

                &:nth-child(2) {
                    width: 380px;

                    @include r.sp {
                        width: 280px;
                        margin: 0 auto;
                    }
                }

                &:not(:last-child) {
                    @include r.sp {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    &__content {
        padding: 50px 100px;

        @include r.sp {
            padding: 50px 20px;
        }

        &1 {
            padding: 52px 72px 56px 80px;

            @include r.sp {
                padding: 45px 20px 59px;
            }

            .c-title3 {
                margin-bottom: 43px;

                @include r.sp {
                    margin-bottom: 30px;
                }
            }
        }

        &2 {
            padding: 0 108px 11px 93px;

            @include r.sp {
                padding: 14px 15px 23px;
            }

            .c-title3 {
                margin-right: -18px;

                @include r.sp {
                    margin-right: 0;
                }
            }
        }

        &3 {
            padding: 51px 7px 38px 97px;

            @include r.sp {
                padding: 0;
                margin-bottom: 43px;
            }

            .c-title3 {
                padding-right: 91px;
                letter-spacing: 0px;
                margin-bottom: 24px;

                @include r.sp {
                    padding-right: 0;
                    margin-bottom: 24px;
                }
            }
        }

        &4 {
            padding: 50px 54px 28px 50px;

            @include r.sp {
                padding: 24px 15px 22px;
            }

            .c-title3 {
                margin-bottom: 31px;

                @include r.sp {
                    margin-bottom: 26px;
                }
            }

            p {
                margin-bottom: 24px;

                @include r.sp {
                    margin-bottom: 20px;
                }
            }
        }

        &5 {
            padding: 48px 100px 60px 97px;

            @include r.sp {
                padding: 45px 20px 50px;

                .c-title3 {
                    margin-bottom: 24px;
                }
            }

            .c-article1 {
                &__para {
                    margin-bottom: 23px;

                    @include r.sp {
                        margin-bottom: 13px;
                    }
                }
            }
        }

        &6 {
            padding: 51px 219px 30px 221px;

            @include r.sp {
                padding: 23px 20px 45px;

                .c-title3 {
                    margin-bottom: 24px;
                }
            }

            .c-btn3 {
                margin-top: 20px;
                padding: 14px 35px 14px 17px;

                @include r.sp {
                    margin-top: 37px;
                    flex-direction: column;
                    font-size: 1.4rem;
                    line-height: 22px;
                    padding: 5px 35px 3px 13px;

                    span {
                        font-size: 1.2rem;
                    }
                }
            }

            .c-article1 {
                &__para {
                    margin-bottom: 20px;

                    @include r.sp {
                        margin-bottom: 36px;
                    }
                }
            }
        }
    }

    &__block {
        @include r.sp {
            padding: 0 20px;
        }

        &1 {
            padding: 0 50px 40px;

            @include r.sp {
                padding: 0 20px 50px;
            }

            .c-block1 {
                &__title {
                    @include r.sp {
                        margin-left: -4px;
                        margin-right: -5px;
                    }
                }
            }
        }

        &2 {
            background-color: #f7f7f7;
            padding: 37px 87px 40px 97px;

            @include r.sp {
                padding: 25px 20px 30px;
            }

            .c-title3 {
                margin-bottom: 18px;

                @include r.sp {
                    margin-bottom: 23px;
                }
            }
        }

        &3 {
            padding: 0 41px 50px 54px;

            @include r.sp {
                padding: 0;

                .c-block3 {
                    margin: 0 -2px;
                }
            }
        }

        &4 {
            @include r.sp {
                padding: 0;
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-box1 {
    background: v.$color7;
    box-shadow: 0 0 10px rgba($color: v.$text-color, $alpha: 0.16);
    padding: 23px 22px 80px;
    $root: &;

    @include r.sp {
        box-shadow: none;
        padding: 0;
    }

    //-----------------------------------------------------
    &__inner {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
    }

    //-----------------------------------------------------
    &__wrap {
        + #{$root}__wrap {
            margin: 50px 0 0;

            @include r.sp {
                margin: 40px 0 0;
            }
        }
    }

    .c-imgtext1 {
        margin: 0 0 42px;

        @include r.sp {
            margin: 0 0 40px;
        }
    }

    .c-title3 {
        margin: 0 0 26px;

        @include r.sp {
            margin: 0 0 25px;
        }
    }

    .c-imgtext2 {
        @include r.sp {
            margin: -2px 0 0;
        }
    }

    .c-text {
        + .c-text {
            margin: 30px 0 0;

            @include r.sp {
                margin: 22px 0 0;
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-banner2 {
    background-color: #f9db4f;
    padding-top: 94px;
    padding-bottom: 58px;

    @include r.max(1600px) {
        padding: 60px 0;
    }

    @include r.sp {
        padding-top: 30px;
        padding-bottom: 50px;
    }

    &__title {
        font-size: 3rem;
        font-weight: 700;
        line-height: 48px;
        color: #3b3939;
        margin-bottom: 38px;

        @include r.max(1600px) {
            font-size: 2.6rem;
            margin-bottom: 15px;
        }

        @include r.sp {
            font-size: 2.4rem;
            line-height: 39px;
            margin-bottom: 20px;
        }
    }

    &__desc {
        font-size: 2rem;
        font-weight: 700;
        line-height: 32px;
        color: #3b3939;

        @include r.max(1600px) {
            font-size: 1.6rem;
        }

        @include r.sp {
            font-size: 1.4rem;
            line-height: 22px;
        }

        p {
            margin-bottom: 32px;

            @include r.max(1600px) {
                margin-bottom: 20px;
            }

            @include r.sp {
                margin-bottom: 22px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__content {
        text-align: center;
        background: url(p.$path + "images/about/voice/bg1.png") no-repeat center;
        background-size: contain;
        width: 100%;
        padding: 66px 41px 135px;
        overflow: hidden;

        @include r.max(1600px) {
            width: 80%;
            padding: 66px 40px 135px;
            margin: 0 auto;
        }

        @include r.sp {
            width: 100%;
            border-radius: 31px;
            padding: 44px 15px 65px;
            background: #fff;
            position: relative;
        }
    }

    &__inner {
        position: relative;
        z-index: 2;

        @include r.sp {
            margin: 0 -15px;
        }

        &:before,
        &:after {
            content: "";
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            z-index: 2;

            @include r.sp {
                display: none;
            }
        }

        &:before {
            background-image: url(p.$path + "images/about/voice/bg-left.png");
            width: 334px;
            height: 540px;
            top: -60px;
            left: -260px;

            @include r.max(1600px) {
                width: 30%;
                height: 100%;
                top: 0;
                left: -90px;
            }
        }

        &:after {
            background-image: url(p.$path + "images/about/voice/bg-right.png");
            width: 268px;
            height: 537px;
            top: -67px;
            right: -232px;

            @include r.max(1600px) {
                width: 30%;
                height: 100%;
                top: 0;
                right: -90px;
            }

            @include r.sp {
                display: block;
                content: "";
                background: url(p.$path + "images/about/voice/ic1.svg") no-repeat center;
                background-size: contain;
                width: 57px;
                height: 62px;
                position: absolute;
                left: 41%;
                transform: translateY(-50%);
                bottom: -70px;
                top: auto;
                right: auto;
                z-index: 1;
            }
        }
    }
}

/*
------------------------------------------------------------*/
.c-block5 {
    &__h3 {
        text-align: center;
        margin-bottom: 11px;
        margin-left: -20px;
        font-weight: 700;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 20px;
            margin: 0;
            margin-bottom: 2px;
        }
    }

    &__p {
        margin-bottom: 26px;

        @include r.sp {
            margin-bottom: 18px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.u-dot {
            position: relative;

            @include r.sp {
                display: none;
            }

            &:before {
                content: "・";
                font-size: 1.6rem;
                font-weight: 700;
                line-height: 26px;
                color: #f9db4f;
                position: absolute;
                top: 0;
                left: -20px;
                z-index: 2;
            }
        }
    }

    &__content {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 26px;
        color: #3b3939;
        padding: 26px 50px 36px 70px;
        background-color: #f5f5f5;
        margin-top: 20px;

        @include r.sp {
            font-size: 1rem;
            line-height: 18px;
            padding: 0 8px;
            margin-top: 18px;
        }
    }

    &__wrap {
        max-width: 900px;
        width: 90%;
        background: v.$color6;
        padding: 9px 35px;
        margin: 0 auto;

        @include r.sp {
            width: 100%;
            padding: 3px 0;
        }
    }

    &__img {
        margin: 0 0 5px;

        img {
            border-radius: 5px;
        }
    }

    &__form {
        iframe {
            width: 100%;
            height: 900px;
        }
    }

    &__inner {
        background-color: #fff;
        box-shadow: 0 0 10px rgba($color: v.$text-color, $alpha: 0.16);
        padding: 40px 100px;

        @include r.sp {
            background-color: #f5f5f5;
            box-shadow: none;
            padding: 20px 15px 24px;
        }
    }

    .c-btn6 {
        margin: 50px 0 100px;

        @include r.sp {
            margin: 30px 0 50px;
        }
    }
}

/*
------------------------------------------------------------*/
.c-tab1 {
    $root: &;

    //-----------------------------------------------------
    &__link {
        display: flex;
        position: relative;

        // overflow: auto;
        @include r.sp {
            flex-wrap: nowrap;
        }

        &::after {
            content: "";
            width: 100vw;
            height: 1px;
            background: v.$color9;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);

            @include r.sp {
                width: 100%;
            }
        }

        &__item {
            // width: calc(100% / 3);
            // min-width: 33%;
            flex-grow: 1;
            position: relative;
            z-index: 2;

            @include r.sp {
                width: calc(100% / 3);
            }

            &.is-active {
                #{$root}__link__txt {
                    border-color: v.$color2;
                }
            }
        }

        &__txt {
            display: block;
            height: 100%;
            text-align: center;
            font-size: 1.8rem;
            line-height: 1.6;
            color: v.$color8;
            font-weight: bold;
            border-bottom: 5px solid transparent;
            padding: 29px 10px 7px;

            @include r.sp {
                font-size: 1.2rem;
                border-bottom-width: 2px;
                padding: 10px 5px 1px;
                white-space: nowrap;
            }

            @include r.max(350px) {
                font-size: 1rem;
            }
        }
    }

    //-----------------------------------------------------
    &__content {
        background: v.$color7;
        box-shadow: 0 0 10px rgb(51 51 51 / 16%);
        padding: 50px;
        margin: 20px 0 0;

        @include r.sp {
            padding: 40px 0 0;
            margin: 0;
            box-shadow: none;
        }
    }

    //-----------------------------------------------------
    &__item {
        // display: none;
        // padding: 50px 0 0;

        // @include r.sp {
        //     padding: 40px 0 0;
        // }

        &.is-active {
            display: block;
        }
    }

    .c-btn4 {
        margin-top: 20px;

        @include r.sp {
            margin-top: 71px;
        }
    }
}

/*
------------------------------------------------------------*/
.c-link {
    font-size: 1.6rem;
    line-height: 2;
    color: v.$text-color;
    font-weight: 500;

    @include r.sp {
        font-size: 1.2rem;
        line-height: 1.84;
    }

    a {
        text-decoration: underline;
    }
}

/*
------------------------------------------------------------*/
.c-accordion2 {
    border-top: 1px solid v.$color9;
    $root: &;

    &:last-child {
        border-bottom: 1px solid v.$color9;

        @include r.sp {
            border-bottom: none;
        }
    }

    //-----------------------------------------------------
    &__heading {
        position: relative;
        padding: 21px 40px 18px 0;
        cursor: pointer;

        @include r.sp {
            padding: 26px 25px 26px 0;
        }

        &::after {
            content: "";
            width: 16px;
            height: 2px;
            background: v.$color2;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(0, -50%);
            margin: -2px 0 0;

            @include r.sp {
                margin: 0;
                right: 0;
            }
        }

        &::before {
            content: "";
            width: 2px;
            height: 16px;
            background: v.$color2;
            position: absolute;
            top: 50%;
            right: 27px;
            transform: translate(0, -50%);
            margin: -2px 0 0;
            transition: all 0.3s ease-in-out;

            @include r.sp {
                margin: 0;
                right: 7px;
            }
        }

        &.is-open {
            &::before {
                transform: translate(0, -50%) rotate(90deg);
            }
        }
    }

    //-----------------------------------------------------
    &__ttl {
        min-height: 50px;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        line-height: 1.6;
        color: v.$text-color;
        font-weight: bold;
        position: relative;
        padding: 0 0 0 80px;

        @include r.sp {
            min-height: 35px;

            font-size: 1.4rem;
            line-height: 1.72;
            padding: 0 0 0 40px;
        }

        &::before {
            content: "Q";
            width: 50px;
            height: 50px;
            text-align: center;
            background: #000000;
            border-radius: 50%;
            font-size: 2.6rem;
            line-height: 50px;
            color: v.$color7;
            @include r.RobotoCondensed;
            position: absolute;
            top: -2px;
            left: 0;

            @include r.sp {
                width: 30px;
                height: 30px;
                font-size: 2.2rem;
                line-height: 30px;
                top: 3px;
            }
        }
    }

    //-----------------------------------------------------
    &__info {
        position: relative;
        padding: 21px 0 23px 80px;
        display: none;

        @include r.sp {
            padding: 3px 25px 29px 40px;
        }

        &::before {
            content: "A";
            width: 50px;
            height: 50px;
            text-align: center;
            background: v.$color2;
            border-radius: 50%;
            font-size: 2.6rem;
            line-height: 50px;
            color: v.$color7;
            @include r.RobotoCondensed;
            position: absolute;
            top: 10px;
            left: 0;

            @include r.sp {
                width: 30px;
                height: 30px;
                font-size: 2.2rem;
                line-height: 30px;
                top: 5px;
            }
        }
    }

    //-----------------------------------------------------
    &__txt {
        font-size: 1.6rem;
        line-height: 2;
        color: v.$text-color;
        font-weight: 500;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 1.83;
        }

        + #{$root}__txt {
            margin: 32px 0 0;

            @include r.sp {
                margin: 22px 0 0;
            }
        }
    }

    .c-link {
        margin: 32px 0 0;

        @include r.sp {
            margin: 20px 0 0;
        }
    }
}

/*
------------------------------------------------------------*/
.c-box2 {
    border-bottom: 1px solid v.$color9;
    padding: 0 0 19px;

    @include r.sp {
        padding: 0 0 14px;
    }

    //-----------------------------------------------------
    &__detail {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    //-----------------------------------------------------
    &__time {
        font-size: 1.6rem;
        line-height: 1.6;
        color: #b1b1b1;
        font-weight: bold;
        margin: 0 21px 0 0;
    }

    // .c-tag1 {
    //     min-width: 130px;

    //     @include r.sp {
    //         min-width: 100px;
    //         font-size: 1.2rem;
    //         padding: 6px 7px 4.5px;
    //     }
    // }

    //-----------------------------------------------------
    &__ttl {
        font-size: 2.4rem;
        line-height: 1.65;
        color: v.$color8;
        font-weight: bold;
        margin: 15px 0 0;

        @include r.sp {
            font-size: 1.8rem;
            line-height: 1.62;
            margin: 17px 0 0;
        }
    }

    //-----------------------------------------------------
    &__img {
        margin: 22px auto 0;
        position: relative;
        overflow: hidden;
        &::after {
            content: "";
            display: block;
            padding-top: 56.25%;
        }
        @include r.sp {
            margin-top: 14px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }
    }

    &__video {
        width: 100%;
        height: 445px;
        margin-top: 22px;

        @include r.sp {
            height: 400px;
            margin-top: 14px;
        }

        @include r.max(480px) {
            height: 47.8vw;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .c-social {
        margin: 20px 0 0;

        @include r.sp {
            margin: 15px 0 0;
        }
    }
}

/*
------------------------------------------------------------*/
.c-social {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    //-----------------------------------------------------
    &__item {
        width: 40px;

        @include r.sp {
            background: #1d9bf0;
            width: auto;
            border-radius: 20px;
        }

        &:not(:first-child) {
            margin: 0 0 0 20px;
        }

        a {
            @include r.sp {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 2px 10px 0 10px;
            }
        }
    }

    //-----------------------------------------------------
    &__icon {
        @include r.sp {
            width: 18px;
        }

        img {
            width: 100%;
        }
    }

    //-----------------------------------------------------
    &__txt {
        display: none;

        @include r.sp {
            display: block;
            font-size: 1.2rem;
            line-height: 1.5;
            color: #fff;
            font-weight: 500;
        }
    }
}

/*
------------------------------------------------------------*/
.c-box3 {
    padding: 16px 0 0;
    $root: &;

    @include r.sp {
        padding: 0;
    }

    + #{$root} {
        margin: 100px 0 0;

        @include r.sp {
            border-top: 1px solid v.$color4;
            margin: 40px 0 0;
            padding: 51px 0 0;
        }
    }

    //-----------------------------------------------------
    &__inner {
        border: 2px solid v.$color4;
        border-radius: 50px;
        padding: 0 48px 38px;

        @include r.sp {
            border: none;
            border-radius: 0;
            padding: 0;
        }
    }

    //-----------------------------------------------------
    &__heading {
        text-align: center;
        font-size: 2.2rem;
        line-height: 1.5;
        color: v.$color4;
        font-weight: bold;
        margin: -16px 0 0;

        @include r.sp {
            font-size: 1.4rem;
            line-height: 1.6;
        }

        &__inner {
            background: v.$color7;
            padding: 0 27px;

            @include r.sp {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
            }
        }

        &__txt {
            @include r.sp {
                max-width: calc(100% - 40px);
            }
        }

        &__icon {
            margin: 0 0 0 17px;
            position: relative;
            top: -2px;

            @include r.sp {
                width: 30px;
                order: -1;
                top: 0;
                margin: 0 10px 0 0;
            }

            img {
                display: inherit;
                width: 30px;

                @include r.sp {
                    width: 100%;
                }
            }
        }
    }

    //-----------------------------------------------------
    &__ttl {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 27px 0 0;

        @include r.sp {
            margin: 23px 0 0;
        }
    }

    //-----------------------------------------------------
    &__ttlsub {
        font-size: 1.6rem;
        line-height: 1.5;
        color: v.$color8;
        font-weight: bold;
        margin: 0 12px 0 0;

        @include r.sp {
            font-size: 1.2rem;
            margin: 0 9px 0 -8px;
        }
    }

    //-----------------------------------------------------
    &__ttlmain {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 72%, v.$color2 72%);
        font-size: 3rem;
        line-height: 1.35;
        color: v.$color4;
        font-weight: bold;

        @include r.sp {
            font-size: 1.8rem;
            line-height: 1.5;
        }
    }

    //-----------------------------------------------------
    &__info {
        margin: 27px 0 0;

        @include r.sp {
            margin: 24px 0 0;
        }
    }

    //-----------------------------------------------------
    &__txt {
        font-size: 1.6rem;
        line-height: 2.25;
        color: v.$color4;
        font-weight: bold;

        @include r.sp {
            font-size: 1.2rem;
            line-height: 1.84;
        }

        + #{$root}__txt {
            margin: 36px 0 0;

            @include r.sp {
                margin: 22px 0 0;
            }
        }
    }

    //-----------------------------------------------------
    &__detail {
        width: 89%;
        margin: 27px auto 0;

        @include r.sp {
            width: 100%;
            margin: 25px 0 0;
        }
    }

    //-----------------------------------------------------
    &__ttl2 {
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.5;
        color: #fff;
        font-weight: bold;
        position: relative;

        @include r.sp {
            font-size: 1.4rem;
        }

        &::after {
            content: "";
            width: 100%;
            height: 2px;
            background: url(p.$path + "images/common/line.png") repeat-x left center;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);

            @include r.sp {
                display: none;
            }
        }

        span {
            display: inline-block;
            background: v.$color4;
            padding: 5px 15px 2px;
            position: relative;
            z-index: 2;

            @include r.sp {
                display: block;
                padding: 6px 10px 3px;
            }
        }
    }

    //-----------------------------------------------------
    &__img {
        margin: 20px 0 0;

        img {
            width: 100%;
        }
    }

    .c-btn5 {
        margin-top: 21px;

        @include r.sp {
            margin-top: 30px;
        }
    }
}

// c-article2
.u-img1 {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
}

.c-article2 {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 30px;
    color: v.$text-color;

    @include r.sp {
        font-size: 1.2rem;
        line-height: 22px;
    }

    &__ttl {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 30px;
        color: v.$text-color;
        margin-bottom: 1px;

        @include r.sp {
            font-size: 1.4rem;
            line-height: 24px;
            margin-bottom: 0;
        }
    }

    p {
        margin-bottom: 30px;

        @include r.sp {
            margin-bottom: 22px;
        }

        &.u-mb0,
        &:last-child {
            margin-bottom: 0;
        }
    }

    figure {
        display: block;
    }

    .u-img1 {
        margin-top: 32px;

        @include r.sp {
            margin-top: 25px;
        }
    }

    .u-img2 {
        margin-top: -5px;

        @include r.sp {
            margin: 0 -42px 0 -38px;
            margin-top: 17px;
        }
    }
}

.c-notfound {
    text-align: center;
    padding: 100px 0;

    @include r.sp {
        padding: 50px 0;
    }
}
