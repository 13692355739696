@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.c-breadcrumb {
  position: relative;
  padding: 19px 0 25px 28px;
  display: flex;

  @include r.sp {
    padding: 11px 0 12px 15px;
  }

  &::before {
    content: '';
    width: 21px;
    height: 18px;
    background: url(p.$path + 'images/common/icon-home.png') no-repeat center / cover;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
    margin-top: -4px;

    @include r.sp {
      width: 13px;
      height: 11px;
      left: -4px;
      margin-top: -1px;
    }
  }

  &__item {
    font-size: 1.768rem;
    line-height: 1.833rem;
    font-weight: 300;
    color: #6d6d6d;
    white-space: nowrap;

    @include r.sp {
      font-size: 1.167rem;
      line-height: 1.2rem;
    }

    &:not(:last-child) {
      margin-right: 18px;

      @include r.sp {
        margin-right: 10px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding-right: 33px;
    position: relative;

    @include r.sp {
      padding-right: 20px;
    }

    @include r.hover {
      text-decoration: underline;
    }

    &::after {
      content: '＞';
      font-size: 1.178rem;
      position: absolute;
      text-decoration: none;
      top: 2px;
      right: 0;

      @include r.sp {
        font-size: 1rem;
        transform: scale(0.8);
        top: 0;
      }
    }
  }
}