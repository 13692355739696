// $filePath: '/dirname/assets/';

$base-color: #b5344e;
$text-color: #333;
$color1: #e9b54a;
$color2: #f9db4f;
$color3: #fceebf;
$color4: #2f304d;
$color5: #011f64;
$color6: #f5f5f5;
$color7: #fff;
$color8: #3b3939;
$color9: #e4e4e4;
$color10: #707070;
$color11: #fabd00;
